<template>
  <div>
    <div
      ref="wrapRef"
      class="flex gap-2.5 items-center relative"
    >
      <ui-button
        v-if="enabledActions"
        icon-left="chevron_left"
        variant="link"
        hide-space
        size="xl-icon"
        class="absolute left-0 top-1/2 -translate-y-1/2 sm:text-4xl text-3xl"
        @click="setIndex()"
      />
      <div class="flex-1 overflow-hidden rounded-lg fade-in-image">
        <div
          v-for="(attachment, index) in attachments"
          v-show="index === currentIndex"
          :key="attachment.url"
          class="aspect-auto h-[60vh] min-h-80 flex justify-center items-center"
        >
          <ui-file-preview
            :src="attachment.url"
            :file-name="attachment.name"
            wrap-class="h-auto w-auto"
            class="object-contain max-w-full max-h-full mx-auto"
          />
        </div>
      </div>
      <ui-button
        v-if="enabledActions"
        icon-left="chevron_right"
        variant="link"
        hide-space
        size="xl-icon"
        class="absolute right-0 top-1/2 -translate-y-1/2 sm:text-4xl text-3xl"
        @click="setIndex(true)"
      />
    </div>
    <slot :attachment="attachments[currentIndex]" />
  </div>
</template>

<script setup lang="ts">
  import { onKeyStroke } from '@vueuse/core';

  type Props = {
    attachments: Attachment[] | MessageAttachment[];
    initIndex?: number;
  };
  const wrapRef = ref<HTMLElement>();
  const props = defineProps<Props>();

  const emits = defineEmits(['select']);
  const currentIndex = ref(props.initIndex || 0);
  const enabledActions = computed(() => props.attachments.length > 1);

  const setIndex = (isInc = false) => {
    if (enabledActions.value) {
      const lastIndex = props.attachments.length - 1;
      currentIndex.value = isInc
        ? (currentIndex.value + 1) % (lastIndex + 1)
        : (currentIndex.value - 1 + (lastIndex + 1)) % (lastIndex + 1);
    }
    emits('select', props.attachments[currentIndex.value]);
  };

  watch(
    () => props.attachments.length,
    (newLength, oldLength) => {
      if (newLength < oldLength && (currentIndex.value >= newLength || currentIndex.value < 0)) {
        currentIndex.value = Math.max(0, newLength - 1);
      }
    }
  );

  onKeyStroke('ArrowLeft', () => setIndex());
  onKeyStroke('ArrowRight', () => setIndex(true));
  useSwipe(wrapRef, {
    onSwipeEnd(_, direct) {
      if (['right', 'left'].includes(direct)) {
        setIndex(direct === 'right');
      }
    }
  });
</script>

<style lang="scss">
  .fade-in-image {
    animation: fadeIn 2s;
  }
</style>
